@import 'styles/includes.scss';

.CardImageLink {
    $root: &;
    padding: 16px;
    padding-bottom: 20px;
    background-color: $colorBlue50;
    margin-bottom: 32px;
    position: relative;

    @include media(m) {
        padding: 32px;
    }

    &::after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        bottom: 0;
        right: 0;
        border-left: 55px solid transparent;
        border-bottom: 65px solid $colorWhiteBackground;

        @include media(m) {
            border-left: 71px solid transparent;
            border-bottom: 85px solid $colorWhiteBackground;
        }
    }

    &--Purple50 {
        background-color: $colorPurple50;
    }

    &--Green50 {
        background-color: $colorGreen50;
    }

    &--Blue50 {
        background-color: $colorBlue50;
    }

    &--Orange50 {
        background-color: $colorOrange50;
    }

    &__Link {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
    }

    &__Container {
        display: flex;
        flex-direction: row;
        gap: 1.6rem;

        @include media(m) {
            gap: 2.4rem;
        }
    }

    &__Picture {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        max-width: 80px;

        @include media(m) {
            max-width: 184px;
        }

        & img {
            height: 80px;
            width: 100%;
            object-fit: cover;

            @include media(m) {
                height: 184px;
            }
        }
    }

    &__Content {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.6rem;

        @include media(m) {
            gap: 1.2rem;
        }
    }

    &__Title {
        @extend %h3;
        font-size: 1.4rem;
        line-height: 20px;

        @include media(m) {
            line-height: 30px;
            font-size: 2.2rem;
        }

        @include media(l) {
            font-size: 2.4rem;
        }
    }

    &__Text {
        @extend %body-copy;
        font-size: 1.4rem;

        @include media(m) {
            font-size: 1.6rem;
        }

        @include media(l) {
            font-size: 1.8rem;
        }
    }

    &__EventLinkText {
        font-family: $fontBrand;
        font-size: 1.4rem;
        letter-spacing: 0.03rem;

        @include media(m) {
            font-size: 1.6rem;
        }

        @include media(l) {
            font-size: 1.8rem;
        }
    }

    &__EventLink {
        display: flex;
        align-items: center;
        font-size: 1.4rem;
        color: $colorPink;
        position: relative;
        width: fit-content;
        line-height: normal;
        gap: 0.8rem;
        margin-right: 3.2rem;

        &::before,
        &::after {
            content: '';
            backface-visibility: hidden;
            width: 100%;
            height: 0.2rem;
            position: absolute;
            background-color: $colorPink600;
            bottom: -4px;
            left: 0px;
            transition: transform 0.6s cubic-bezier(0.77, 0, 0.175, 1),
                -webkit-transform 0.6s cubic-bezier(0.77, 0, 0.175, 1);
        }

        &::before {
            transform: scaleX(1) skewX(0deg);
            transform-origin: left center;
            transition-delay: $transitionDelayFast;
        }
        &::after {
            transform: scaleX(0) skewX(0deg);
            transform-origin: right center;
            transition-delay: 0s;
        }

        .CardImageLink:hover &:before {
            transform: scaleX(0) skewX(45deg);
            transition-delay: 0s;
            transform-origin: right center;
        }

        .CardImageLink:hover &:after {
            transform: scaleX(1) skewX(45deg);
            transition-delay: $transitionDelayFast;
            transform-origin: left center;
        }
    }

    span {
        width: 1.4rem;

        @include media(m) {
            width: 2.4rem;
        }
    }
}
